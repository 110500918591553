html, body, #root {
  height: 100%;
  overflow: hidden;
}

#root {
  overflow-y: auto;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input, textarea {
  outline: none !important;
}

a {
  text-decoration: none;
  color: #501c82;
}

a:hover {
  color: rgb(115, 12, 182);
}